import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme } from '@mui/material/styles';
import {ThemeProvider} from "@emotion/react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import ReCAPTCHA from 'react-google-recaptcha';
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import {useState} from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import validator from 'validator'
import { ToastContainer, toast } from 'react-toastify';
import LaunchIcon from '@mui/icons-material/Launch';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const theme = createTheme({
    palette: {
      // mode:'dark',
      primary:{
        main: '#b71c1c'
      },
      secondary: {
        main: '#e57373'
      },
      text: {
        // primary: "#FFFFFF"
        // primary: '#fd6a6a'
      }
    },
    components:{
      MuiLoadingButton: {
        defaultProps:{
          variant: 'contained'
        },
      },
      MuiButton:{
        defaultProps:{
          variant: 'contained'
        },
        styleOverrides:{
          root:{
            marginRight: 15
          }
        }
      },
      MuiTextField:{
        defaultProps:{
          margin: 'dense'
        },
        styleOverrides:{
          root:{
            marginBottom: '30px'
          }
        }
      },
      MuiBox:{
        styleOverrides:{
          root:{
            marginBottom: '30px'
          }
        }
      },
      MuiCssBaseline:{
        styleOverrides: {
          body: {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) ,url('${process.env.PUBLIC_URL}/suburbs.jpg')`,
            backgroundSize: 'cover'
          }
        }
      },
      MuiPaper:{
        styleOverrides:{
          root:{
            color: '#000',
            backgroundColor: '#ffffffd4'
          }
        }
      }
    }
  })
  initializeApp({
    apiKey: "AIzaSyCA0TMtFYbi1Ajk7TE7QMyxQLG7_1VYGrc",
    authDomain: "cardinal-knolls-hoa-site.firebaseapp.com",
    projectId: "cardinal-knolls-hoa-site",
    storageBucket: "cardinal-knolls-hoa-site.appspot.com",
    messagingSenderId: "977162938777",
    appId: "1:977162938777:web:43386d18520610f1e78b08",
    measurementId: "G-PP1FXGR3CW"
  });

  // user entered information
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [homeNumber, setHomeNumber] = useState('');
  const [message, setMessage] = useState('');
  const [captcha, setCaptcha] = useState('');

  // error states
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [homeNumberError, setHomeNumberError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  // UI states
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    const functions = getFunctions();
    const validateCaptcha = httpsCallable(functions, 'validateCaptcha');

    if(!validateForm()){
      setLoading(false);
      return;
    }

    try{
      await validateCaptcha({
        name, email, homeNumber,
        message, captcha
      });
      setSubmitted(true)
    }catch (e){
      console.error(e)
      toast.error('There was an error submitting your form.  Please try again later.')
    }
    setLoading(false);
  };

  const validateForm = () => {
    let bReturn = true;

    if(name === ''){
      bReturn = false
      toast.error('Please enter your name');
      setNameError(true)
    }else{
      setNameError(false)
    }

    if(email === '' || !validator.isEmail(email)){
      bReturn = false
      toast.error('Please enter your email address.');
      setEmailError(true)
    }else{
      setEmailError(false)
    }

    if(homeNumber === ''){
      bReturn = false
      toast.error('Please enter your home number.');
      setHomeNumberError(true)
    }else{
      setHomeNumberError(false)
    }

    if(message === ''){
      bReturn = false
      toast.error('Please enter your message.');
      setMessageError(true)
    }else{
      setMessageError(false)
    }

    if(captcha === ''){
      bReturn = false
      toast.error(`Please confirm you're not a robot.`);
      setCaptchaError(true)
    }else{
      setCaptchaError(false)
    }

    return bReturn;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={'xl'} >
        <Box sx={{flexGrow: 1}} >
            <Grid container spacing={2} sx={{ height: '100vh', alignItems: 'center' }}>
              <Grid item xs={12} lg={6}>
                <Box mb={2}>
                  <Typography variant={'h2'} sx={{color: '#fd6a6a'}}>
                    <b>Cardinal Knolls</b><br />Homeowners Association
                  </Typography>
                </Box>
                <Box>
                  <Typography variant={'h6'} sx={{color: '#ffffff'}}>
                    Use this form to contact your HOA board members.
                  </Typography>
                  <Button 
                    component={'a'} 
                    href={`https://login.clickpay.com/metropolis`} 
                    target={'blank'} 
                    sx={{'marginTop': 1}}
                    endIcon={<LaunchIcon />}
                  >Click pay Login</Button>
                  <Button 
                    component={'a'} 
                    href={`${process.env.PUBLIC_URL}/ClickPay.pdf`} 
                    target={'blank'} 
                    sx={{'marginTop': 1}}
                    endIcon={<LaunchIcon />}
                  >Click pay instructions</Button>
                </Box>

              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper sx={{padding: 3}}>
                  {submitted && (
                    <Typography margin={5}>
                      Thank you for submitting our form.  Someone should be with you soon.
                    </Typography>
                  )}
                  {!submitted && (
                    <>
                      <TextField
                        id="name"
                        label="Your Name"
                        helperText={'Please enter your name so we know how to address you.'}
                        value={name}
                        onChange={e => {
                          setNameError(false)
                          setName(e.target.value)
                        }}
                        error={nameError}
                        fullWidth
                      />
                      <TextField
                        id="email"
                        label="Your Email"
                        helperText={'Please enter your email address so the board can contact you if needed with additional information'}
                        value={email}
                        onChange={e => {
                          setEmailError(false)
                          setEmail(e.target.value)
                        }}
                        error={emailError}
                        fullWidth
                      />
                      <TextField
                        id="homeNumber"
                        type={'number'}
                        label="Home Number"
                        helperText={'Enter your home address, number only.'}
                        value={homeNumber}
                        onChange={e => {
                          setHomeNumberError(false)
                          setHomeNumber(e.target.value)
                        }}
                        error={homeNumberError}
                        fullWidth
                      />
                      <TextField
                        id="message"
                        type={'text'}
                        label="Message"
                        helperText={'What can we do for you?'}
                        rows={4}
                        value={message}
                        onChange={e => {
                          setMessageError(false)
                          setMessage(e.target.value)
                        }}
                        error={messageError}
                        fullWidth
                        multiline
                      />
                      <ReCAPTCHA
                        sitekey="6LebqycpAAAAAFbTr8r0HvGxWAyGeYcOCucslhqM"
                        size="normal"
                        render="explicit"
                        onChange={key => {
                          setCaptchaError(false)
                          setCaptcha(key)
                        }}
                      />
                      {captchaError && (
                        <Typography component={'p'} sx={{color: 'red'}}>
                          Please confirm that you're not a robot by clicking the above checkbox.
                        </Typography>
                      )}
                      <Typography variant={'caption'}>
                        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                      </Typography>
                      <Box pt={4}>
                        <LoadingButton
                          onClick={onSubmit}
                          loading={loading}
                          loadingPosition="end"
                          endIcon={<SendIcon />}
                        >Send</LoadingButton>
                      </Box>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
        </Box>
      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
